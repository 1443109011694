import * as React from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useTranslation } from "react-i18next";
import { useState } from "react";

dayjs.locale("es");

const InputDate = ({ setData, data, defaultValue }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState(defaultValue);

  const handleDateChange = (newValue) => {
  
    setData({
      ...data,
      date: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
    });
  };

  const [open, setOpen] = useState(false);
  console.log(data)
  return (
    <div
      onClick={(e) => {
        setOpen(true);
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={t("reports.date")}
            required
            name="date"
            sx={{
              width: "95%",
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            value={dayjs(defaultValue)}
            onChange={(newValue) => {                
              setSelectedDate(newValue);
              setData({
                ...data,
                date: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
              });
              setTimeout(() => {
                setOpen(false);
              }, 100);
            }}
            // inputProps={{
            //   readOnly: true,
            // }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default InputDate;
