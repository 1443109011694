import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import { useTranslation } from "react-i18next";
import { StyledForm } from "../../styles/Styles";
import { useEffect, useState } from "react";
const DatePickerAutoOpen = ({ open, data, setOpen, setData, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        required
        name="date"
        value={data.date ? dayjs(data.date) : null} // establece el valor aquí
        open={open}
        sx={{ width: "95%" }}
        onChange={(newValue) => {
          setData({
            ...data,
            date: newValue ? dayjs(newValue).format("YYYY-MM-DD") : "",
          });
          setTimeout(() => {
            setOpen(false);
          }, 100);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

export default DatePickerAutoOpen;
