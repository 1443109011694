import React from 'react'
import Create2 from './by_h/Create2'

const View2 = () => {
  return (
    <>
      <Create2/>
    </>
  )
}

export default View2
